import React from 'react';
import { Box, Typography, Grid, Card, CardContent, Avatar } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import WorkIcon from '@mui/icons-material/Work';
import teslaImage from '../assets/images/tesla.png';
import bydImage from '../assets/images/carRight.png';


const fleets = [
  {
    name: 'BYD Atto 3',
    persons: 4,
    largeBags: 2,
    smallBags: 2,
    image: bydImage,
  },
  {
    name: 'Tesla 3',
    persons: 4,
    largeBags: 2,
    smallBags: 2,
    image: teslaImage,
  },
];

export default function Fleet() {
  return (
    <Box sx={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Our Fleets
      </Typography>

      <Grid container spacing={3}>
        {fleets.map((fleet, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ borderRadius: '16px', boxShadow: 3 }}>
              <CardContent sx={{ textAlign: 'center' }}>
                <Avatar
                  alt={fleet.name}
                  src={fleet.image}
                  variant="square"
                  sx={{ width: '350px', height: '200px', marginBottom: '15px' }}
                />
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  {fleet.name}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <PeopleIcon color="primary" />
                    <Typography variant="body2">{fleet.persons} Persons</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <WorkIcon color="primary" />
                    <Typography variant="body2">{fleet.largeBags} Large Bags</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <LocalMallIcon color="primary" />
                    <Typography variant="body2">{fleet.smallBags} Small Bags</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
