import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  CircularProgress,
  Button,
  Grid,
  Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";
import { fetchSessionUrl, updateProfile } from "../services/api";

const stripePromise = loadStripe("pk_live_51O4dbmEWXZ4M1jtMyq63OPmSCtL9F4xs0e5OJfTn5apIWuAqYOAlDjGLDrS3d2BRfTpt2GjC3VwKlxC4kozrLGFW00ipNzrfel");

const AddCardModal = ({ closeModal, book }) => {
  const [clientSecret, setClientSecret] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchSecret = async () => {
    try {
      const response = await fetchSessionUrl(localStorage.getItem("token"));

      if (response && response.data && response.data.success) {
        setClientSecret(response.data.clientSecret);
      }
    } catch (error) {
      console.error("Error fetching session URL:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSecret();
  }, []);

  const options = {
    clientSecret,
    onComplete: async () => {
      try {
        const formData = new FormData();

        // Append form fields to the formData object
        formData.append('hasPaymentDetails', true);
        const response = await updateProfile(formData, localStorage.getItem("token"));
        if (response.status === 200) {
          await closeModal();
          book();
       
        } else {
          toast.error("Error in adding card");
        }
      } catch (error) {
        toast.error("Error in adding card");
      }
    }
  };

  return (
    <Dialog
      open={true}
      onClose={closeModal}
      fullWidth
      maxWidth="sm"
      PaperProps={{ sx: { borderRadius: 2, p: 2 } }} // Styling the Dialog
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" component="div">Add Card Details</Typography>
        <IconButton onClick={closeModal} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {/* Conditional rendering based on loading state */}
        {isLoading ? (
          <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 200 }}>
            <CircularProgress />
          </Grid>
        ) : (
          clientSecret && (
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          )
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AddCardModal;
