import React, { useState, useRef } from 'react';
import { Box, TextField, Typography, Button } from '@mui/material';
import { verifyOTP } from '../services/api';

export default function VerifyEmail({ onNext,email }) {
  const [otp, setOtp] = useState(['', '', '', '']);
  const inputRefs = useRef([]);

  const handleChange = (index, value) => {
    if (/^\d?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }

      if (newOtp.every((digit) => digit !== '')) {
        handleSubmit(newOtp);
      }
    }
  };

  const handleBackspace = (index, e) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleSubmit = async (filledOtp) => {
    console.log('OTP Entered:', filledOtp.join(''));

    const resp = await verifyOTP({email:email,otp:parseInt(filledOtp.join(''))},"");
    if(resp.status === 200 && resp.data.success){
      onNext();
    }else{
      alert("Invalid OTP")
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      // bgcolor="#f7f7f7"
    >
      <Box
        width="100%"
        maxWidth="400px"
        p={4}
        boxShadow={3}
        borderRadius={2}
        bgcolor="white"
      >
        <Typography variant="h5" gutterBottom textAlign="center">
          Verify Email ID
        </Typography>
        <Typography variant="body2" gutterBottom textAlign="center">
          Enter the Verification code sent to {email}
        </Typography>
        <Box display="flex" justifyContent="center" mt={3} gap={2}>
          {otp.map((_, index) => (
            <TextField
              key={index}
              value={otp[index]}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyDown={(e) => handleBackspace(index, e)}
              inputRef={(el) => (inputRefs.current[index] = el)}
              inputProps={{
                maxLength: 1,
                style: { textAlign: 'center', fontSize: '1.5rem' },
              }}
              sx={{
                width: '60px',
                height: '60px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  borderWidth: 2,
                },
              }}
            />
          ))}
        </Box>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 4, py: 1.5, fontSize: '1rem' }}
          onClick={() => handleSubmit(otp)}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
}
