import React, { useState } from 'react';
import { Box, Grid, Typography, TextField, Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import dayjs from 'dayjs';
import { fetchPartnerInvoices } from '../services/api';
import { Link } from 'react-router-dom';

export default function ReportsDownload() {
  const [invoiceStartDate, setInvoiceStartDate] = useState(null);
  const [invoiceEndDate, setInvoiceEndDate] = useState(null);
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state

  // Function to fetch booking details based on date range
  const fetchBookingDetails = async () => {
    if (invoiceStartDate && invoiceEndDate) {
      setLoading(true); // Start loading
      try {
        const response = await fetchPartnerInvoices(
          {
            startDate: dayjs(invoiceStartDate).format('YYYY-MM-DD'),
            endDate: dayjs(invoiceEndDate).format('YYYY-MM-DD'),
          },
          localStorage.getItem('token')
        );
        setBookings(response.data); // Assuming API returns booking details as response.data
      } catch (error) {
        console.error('Error fetching booking details:', error);
      } finally {
        setLoading(false); // Stop loading
      }
    }
  };

  // Convert UTC bookingTime to Melbourne time
  const formatBookingTime = (utcTime) => {
    const melbourneTime = new Date(utcTime).toLocaleString('en-AU', {
      timeZone: 'Australia/Melbourne',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
    return melbourneTime;
  };

  return (
    <Box sx={{ padding: '20px', maxWidth: '1000px', margin: '0 auto' }}>
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Invoice Reports & Downloads
      </Typography>

      <Grid container spacing={4}>
        {/* Invoice Download Section */}
        <Grid item xs={12}>
          <Card sx={{ padding: '20px' }}>
            <Typography variant="h6" fontWeight="bold" mb={2}>
              Download Invoice
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="Start Date"
                    value={invoiceStartDate}
                    onChange={(newValue) => setInvoiceStartDate(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="End Date"
                    value={invoiceEndDate}
                    onChange={(newValue) => setInvoiceEndDate(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              sx={{ marginTop: '20px' }}
              onClick={fetchBookingDetails}
              disabled={!invoiceStartDate || !invoiceEndDate || loading} // Disable if no date selected or loading
            >
              {loading ? 'Fetching...' : 'Fetch Invoices'}
            </Button>
            {loading && (
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                <CircularProgress />
              </Box>
            )}
          </Card>
        </Grid>

        {/* Table to Display Booking Details */}
        {bookings.length > 0 && !loading && (
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight="bold" mt={4} mb={2}>
              Total Bookings: {bookings.length}
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sr. No.</TableCell>
                    <TableCell>User Name</TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Total Fare</TableCell>
                    <TableCell>Booking Time</TableCell>
                    <TableCell>Invoice</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bookings.map((booking, index) => (
                    <TableRow key={booking._id}>
                      <TableCell>{index + 1}</TableCell> {/* Serial Number */}
                      <TableCell>{booking.fullName}</TableCell>
                      <TableCell>{booking.phoneNumber}</TableCell>
                      <TableCell>{booking.totalFare}</TableCell>
                      <TableCell>{formatBookingTime(booking.bookingTime)}</TableCell>
                      <TableCell>
                        <Link to={`https://prod.goblu-ev.com/v1/ops/invoicegen/${booking.bookingId}`} target="_blank" rel="noopener noreferrer">
                          View Invoice
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
