import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, IconButton, Menu, MenuItem, Divider, Grid, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import WorkIcon from '@mui/icons-material/Work';
import leaf from '../assets/images/leaf.png';
import car from '../assets/images/car.png';
import person from '../assets/images/people.png';
import large from '../assets/images/large-bag.png';
import small from '../assets/images/small-bag.png';
import { cancelBooking, pastBookingsApi } from '../services/api';

export default function UpcomingRides() {
  const [showRideDetails, setShowRideDetails] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [bookings, setBookings] = useState([]); // State to store all bookings
  const [selectedBooking, setSelectedBooking] = useState(null); // State to store the selected booking
  const [loading, setLoading] = useState(true); // State for loader
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false); // For showing cancel modal
  const [cancelling, setCancelling] = useState(false); // Loader for cancel action

  useEffect(() => {
    fetchBookings();
  }, []);

  const fetchBookings = async () => {
    const response = await pastBookingsApi(localStorage.getItem('token'));
    if (response.data.status === 'success' && response.data.bookings.booked.length > 0) {
      setBookings(response.data.bookings.booked);
    }
    setLoading(false);
  };

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'Australia/Melbourne',
  };

  const dateFormatter = (dateString) => {
    return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
  };

  const roundToTwoDecimal = (number) => {
    return Math.round(number * 100) / 100;
  };

  const handleOptionsClick = (event, booking) => {
    setAnchorEl(event.currentTarget);
    setSelectedBooking(booking);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRideDetailsClick = () => {
    setShowRideDetails(true);
    handleMenuClose();
  };

  const handleCancelBookingClick = () => {
    setCancelDialogOpen(true);
    handleMenuClose();
  };

  const handleCancelDialogClose = () => {
    setCancelDialogOpen(false);
  };

  const handleConfirmCancel = async () => {
    setCancelling(true);
    try {
      await cancelBooking({ bookingId: selectedBooking.id }, localStorage.getItem('token'), selectedBooking.id);
      setCancelDialogOpen(false);
      fetchBookings(); // Re-fetch bookings after successful cancellation
      window.location.reload();
    } catch (error) {
      console.error('Failed to cancel booking:', error);
    }
    setCancelling(false);
  };

  if (loading) {
    return <CircularProgress sx={{ display: 'block', margin: '50px auto' }} />;
  }

  if (bookings.length === 0) {
    return <Typography variant="body1" sx={{ textAlign: 'center', padding: '20px' }}>No upcoming rides</Typography>;
  }

  return (
    <Box sx={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', marginBottom: '30px' }}>Your Upcoming Rides</Typography>
      <Grid container spacing={3}>
        {bookings.map((booking, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box className="upcoming-ride-card" sx={{ padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
              {/* User Name and Employee ID */}
              <Box sx={{ marginBottom: '15px' }}>
                <Typography variant="h6" sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                  {booking.user.fullName}'s Ride
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', color: '#757575' }}>
                  <WorkIcon sx={{ marginRight: '5px', fontSize: '20px' }} />
                  <Typography variant="body2">{booking.user.id}</Typography>
                </Box>
              </Box>

              <Box className="ride-info" sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
                <Typography variant="body2">Booked on: <span>{dateFormatter(booking?.requestedTimestamp)}</span></Typography>
                <IconButton aria-label="options" onClick={(event) => handleOptionsClick(event, booking)}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleRideDetailsClick}>Ride Details</MenuItem>
                  <MenuItem onClick={handleCancelBookingClick}>Cancel Ride</MenuItem>
                </Menu>
              </Box>

              <Box className="location" sx={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '20px' }}>
                <Box className="icons" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div className="circleone"></div>
                  <Divider orientation="vertical" flexItem sx={{ backgroundColor: '#ccc', margin: '5px 0' }} />
                  <div className="circletwo"></div>
                </Box>
                <Box>
                  <Typography variant="body2">{booking.request.startLocation}</Typography>
                  <Divider sx={{ margin: '10px 0', backgroundColor: '#ccc' }} />
                  <Typography variant="body2">{booking.request.endLocation}</Typography>
                </Box>
              </Box>

              <Box className="fare-details" sx={{ marginBottom: '20px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                  <Typography variant="body2">Pickup:</Typography>
                  <Typography variant="body2">{dateFormatter(booking?.expectedStartTime)}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body2">Fixed Fare:</Typography>
                  <Typography variant="body2">A$ {roundToTwoDecimal(booking?.totalFare)}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                  <Typography variant="body2">Pickup Note:</Typography>
                  <Typography variant="body2">{booking?.pickNote}</Typography>
                </Box>
              </Box>

              <Box className="co-info" sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#e0f7fa', padding: '10px', borderRadius: '8px', marginBottom: '20px' }}>
                <img src={leaf} alt="CO2 Saving" style={{ marginRight: '10px' }} />
                <Typography variant="body2" className='m-0 fw-medium'>You will save {roundToTwoDecimal(booking?.co2Saving)}kg CO2 with this ride</Typography>
              </Box>

              <Box className="about-ride" sx={{ marginBottom: '20px' }}>
                <Typography variant="body2">Ride details will be provided 30 mins before the ride. Pay directly to the driver.</Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Cancellation Confirmation Dialog */}
      <Dialog open={cancelDialogOpen} onClose={handleCancelDialogClose}>
        <DialogTitle>Confirm Cab Cancellation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel this cab booking?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDialogClose}>No</Button>
          <Button onClick={handleConfirmCancel} color="error" disabled={cancelling}>
            {cancelling ? <CircularProgress size={20} /> : 'Yes'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
