import React from 'react';
import { Box, Button, Typography } from '@mui/material';

export default function ReviewRequest() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Box textAlign="center">
        <Typography variant="h5" gutterBottom>
          Request in Review!
        </Typography>
        <Typography variant="body2" gutterBottom>
          Thank you for reaching out! Your request for becoming GoBlu-EV Partner is in review.
          Our team will shortly get in touch with you for the onboarding process.
        </Typography>
        <Button
          variant="contained"
          sx={{ mt: 3 }}
          onClick={() => window.location.href = '/'}
        >
          Go To Main Website
        </Button>
      </Box>
    </Box>
  );
}
