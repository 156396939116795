import React, { useState } from 'react';
import Signup from './signup';
import VerifyEmail from './otp';
import ReviewRequest from './verify';
import { Box, Stepper, Step, StepLabel, styled } from '@mui/material';

const steps = ['', '', '']; // Empty labels for steps to hide the labels

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100vh',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const SideImage = styled(Box)(({ theme }) => ({
  flex: 1,
  backgroundColor: '#004AAD',
  backgroundImage: `url('./auth.svg')`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  flex: 1.5,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(4),
  backgroundColor: '#fff',
  [theme.breakpoints.down('md')]: {
    flex: 1,
    padding: theme.spacing(2),
  },
}));

const MultiStepForm = () => {
  const [activeStep, setActiveStep] = useState(0); // 0: Signup, 1: Verify Email, 2: Review Request
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    organizationName: '',
    email: '',
    phone: '',
  });

  const handleNext = (data) => {
    if (data) setFormData(data); // Save form data from Signup
    setActiveStep((prevStep) => prevStep + 1); // Move to the next step
  };

  return (
    <Container>
  
      {/* Form Wrapper */}
      <FormWrapper>
 

        {/* Conditionally render components based on the current step */}
        {activeStep === 0 && <Signup onNext={handleNext} />}
        {activeStep === 1 && <VerifyEmail onNext={handleNext} email={formData.email}/>}
        {activeStep === 2 && <ReviewRequest formData={formData} />}
      </FormWrapper>
          {/* Side Image */}
          <SideImage />

    </Container>
  );
};

export default MultiStepForm;
