import React, { useState, useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Box, Button, Typography, Divider, Grid, CircularProgress } from '@mui/material';
import car from '../assets/images/car.png';
import person from '../assets/images/people.png';
import large from '../assets/images/large-bag.png';
import small from '../assets/images/small-bag.png';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import './new.css';
import { pastBookingsApi } from '../services/api';

export default function PastRides() {
  const [showRideDetails, setShowRideDetails] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state for loader

  useEffect(() => {
    fetchCompletedRides();
  }, []);

  const fetchCompletedRides = async () => {
    try {
      const response = await pastBookingsApi(localStorage.getItem('token'));
      if (response.data.status === 'success' && response.data.bookings.completed.length >= 0) {
        setBookings(response.data.bookings.completed);
      }
    } catch (error) {
      console.error('Error fetching past rides:', error);
    } finally {
      setLoading(false); // Stop loading after data is fetched
    }
  };

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'Australia/Melbourne',
  };

  const dateFormatter = (dateString) => {
    return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
  };

  const roundToTwoDecimal = (number) => {
    return Math.round(number * 100) / 100;
  };

  const handleRideDetailsClick = (booking) => {
    window.open(`https://prod.goblu-ev.com/v1/ops/invoicegen/${booking.id}`);
  };

  const hideRideDetailsModal = () => {
    setShowRideDetails(false);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <CircularProgress size={50} />
      </Box>
    );
  }

  if (bookings.length === 0) {
    return <Typography variant="body1" sx={{ textAlign: 'center', padding: '20px' }}>No past rides</Typography>;
  }

  return (
    <Box sx={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
      <Grid container spacing={3}>
        {bookings.map((booking, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box className="past-ride-card" sx={{ padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
              <Box sx={{ marginBottom: '15px' }}>
                <Typography variant="h6" sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                  {booking.user.fullName}'s Ride
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', color: '#757575' }}>
                  <HomeWorkIcon />
                  <Typography variant="body2">{booking.user.id}</Typography>
                </Box>
              </Box>

              <Box className="ride-info" sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
                <Typography variant="body2">Booked on: <span>{dateFormatter(booking?.requestedTimestamp)}</span></Typography>
              </Box>

              <Box className="location" sx={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '20px' }}>
                <Box className="icons" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div className="circleone"></div>
                  <Divider orientation="vertical" flexItem sx={{ backgroundColor: '#ccc', margin: '5px 0' }} />
                  <div className="circletwo"></div>
                </Box>
                <Box>
                  <Typography variant="body2">{booking.request.startLocation}</Typography>
                  <Divider sx={{ margin: '10px 0', backgroundColor: '#ccc' }} />
                  <Typography variant="body2">{booking.request.endLocation}</Typography>
                </Box>
              </Box>

              <Box className="fare-details" sx={{ marginBottom: '20px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                  <Typography variant="body2">Pickup:</Typography>
                  <Typography variant="body2">{dateFormatter(booking?.expectedStartTime)}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body2">Paid:</Typography>
                  <Typography variant="body2">A$ {roundToTwoDecimal(booking?.totalFare)}</Typography>
                </Box>
              </Box>

              <Box className="past-info" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '15px', backgroundColor: '#e0e0e0', borderRadius: '8px', marginBottom: '20px' }}>
                <Typography variant="body2">Distance: {roundToTwoDecimal(booking?.request.distance)} KM</Typography>
                <Divider orientation="vertical" flexItem sx={{ backgroundColor: '#ccc', margin: '0 10px' }} />
                <Typography variant="body2">Time: {roundToTwoDecimal(booking?.request.time)} Mins</Typography>
                <Divider orientation="vertical" flexItem sx={{ backgroundColor: '#ccc', margin: '0 10px' }} />
                <Typography variant="body2">Co2 Saved: {roundToTwoDecimal(booking?.co2Saving)} Kg</Typography>
              </Box>

              <Button
                variant="contained"
                fullWidth
                onClick={() => handleRideDetailsClick(booking)}
              >
                View Invoice
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>

      {selectedBooking && (
        <SweetAlert
          show={showRideDetails}
          onCancel={hideRideDetailsModal}
          showCloseButton={true}
          showConfirm={false}
          closeOnClickOutside={true}
        >
          <Box sx={{ padding: '20px' }}>
            <Typography variant="h6" gutterBottom>Your Ride Details</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                <img src={car} alt="Car" />
                <Box>
                  <Typography variant="body2">{selectedBooking.vehicle.make} {selectedBooking.vehicle.model}</Typography>
                  <Typography variant="body2"><img src={person} alt="Persons" /> {selectedBooking.vehicle.capacity} Persons</Typography>
                  <Typography variant="body2"><img src={large} alt="Large Bags" /> {selectedBooking.vehicle.largebag} Large Bags</Typography>
                  <Typography variant="body2"><img src={small} alt="Small Bags" /> {selectedBooking.vehicle.smallbag} Small Bags</Typography>
                </Box>
              </Box>
              <Box>
                <Typography variant="body2">Fixed Fare</Typography>
                <Typography variant="h6">A$ {roundToTwoDecimal(selectedBooking.totalFare)}</Typography>
              </Box>
            </Box>
            <Typography variant="body2">Pay directly to the driver after the ride. All cards accepted.</Typography>
          </Box>
        </SweetAlert>
      )}
    </Box>
  );
}
