import React from 'react';
import { Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from 'moment-timezone';

export default function BookingConfirmModal({ open, handleClose, bookingData }) {
  if (!bookingData) return null;

  const {
    riderName,
    totalFare,
    co2Saving,
    expectedStartTime,
    vehicle
  } = bookingData;
  console.log(bookingData)

  // Convert the expected start time to Melbourne time zone
  const melbourneTime = moment(expectedStartTime).tz('Australia/Melbourne').format('ddd, Do MMM, h:mm A');

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ textAlign: 'center' }}>
    <center>
  <div>  <CheckCircleIcon color="success" sx={{ fontSize: '50px', marginBottom: '10px' }} /></div>
    Ride Booked for {riderName}!
    </center>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ marginBottom: '10px' }}>
          <strong>Date & Time:</strong> {melbourneTime}
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '10px' }}>
          <strong> Fare:</strong> A${totalFare.toFixed(2)}
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '10px' }}>
          <strong>CO2 Saved:</strong> {co2Saving} kg
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '10px' }}>
          <strong>Vehicle:</strong> {vehicle.make} {vehicle.model}
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '10px' }}>
          <strong>Pickup Note:</strong> {bookingData.pickNote}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" fullWidth>
         Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
