import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Link, Divider, CircularProgress, Alert } from '@mui/material';
import { styled } from '@mui/system';
import logo from '../assets/images/logo.png';
import { partnerAuth } from '../services/api';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100vh',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  flex: 1.5,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(4),
  backgroundColor: '#fff',
  [theme.breakpoints.down('md')]: {
    flex: 0.6,
  },
}));

const SideImage = styled(Box)(({ theme }) => ({
  flex: 1,
  backgroundColor: '#004AAD',
  backgroundImage: `url('./auth.svg')`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5),
  width: '100%',
  textTransform: 'none',
}));

export default function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false); // Loader state
  const [errorMessage, setErrorMessage] = useState(''); // Error message state

  const handleLogin = async () => {
    setLoading(true); // Start loading
    setErrorMessage(''); // Reset error message

    try {
      const response = await partnerAuth({ username: email, password });

      const { user, tokens } = response.data;

      // Store the user object and access token in localStorage
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', tokens.access.token);

      console.log('Login successful:', user);

      if (user && tokens.access) {
        window.location.reload();
      } else {
        setErrorMessage("Invalid Credentials");
      }
    } catch (error) {
      setErrorMessage("Invalid Credentials");
      console.error('Login failed:', error.response?.data || error.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Container>
      <FormWrapper>
        <div style={{ width: "70%" }}>
          <img src={logo} alt="GoBlu-EV Logo" height={50} width={150} style={{ marginBottom: 24 }} />
          <Typography variant="h4" gutterBottom fontWeight="bold">
            Log into your account!
          </Typography>

          <Divider sx={{ width: '100%', marginY: 2 }}></Divider>

          {errorMessage && (
            <Alert severity="error" sx={{ marginBottom: 2 }}>
              {errorMessage}
            </Alert>
          )}

          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
          />
          <TextField
            label="Password"
            variant="outlined"
            fullWidth
            margin="normal"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading}
          />

          <StyledButton
            variant="contained"
            color="primary"
            onClick={handleLogin}
            disabled={loading} // Disable button while loading
          >
            {loading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : 'Continue'}
          </StyledButton>

          <Link href="/sign-up" variant="body2" sx={{ marginTop: 10 }}>
            Don't have an account? Create new account
          </Link>
        </div>
      </FormWrapper>

      <SideImage />
    </Container>
  );
}
