import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StateProvider } from "./context/StateProvider";
import { initialState } from "./context/initalState";
import reducer from "./context/reducer";
import { LoadScript } from '@react-google-maps/api';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <StateProvider initialState={initialState} reducer={reducer}>
    <LoadScript
                      googleMapsApiKey={"AIzaSyAe_SHITlxLXMzKeHp3Dk_1WpLFB6N4_3Q"}

            libraries={["places"]}
          >
             <LocalizationProvider dateAdapter={AdapterDayjs}>
             <App/>
             </LocalizationProvider>
         
          </LoadScript>
          </StateProvider>
          </BrowserRouter>
        
  </React.StrictMode>
);


reportWebVitals();
