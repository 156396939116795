import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { updateProfile } from '../services/api'; // Assuming you have an API function for profile update

export default function ChangePassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleChangePassword = async () => {
    if (!newPassword || !confirmPassword) {
      setErrorMessage('Both fields are required.');
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    setErrorMessage('');
    
    try {
      // Call the API to update profile
      await updateProfile({ password: newPassword },localStorage.getItem("token"));
      setSuccessMessage('Password updated successfully.');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('Failed to update password:', error);
      setErrorMessage('Failed to update password. Please try again.');
    }
  };

  return (
    <Box
      sx={{
        padding: '20px',
        maxWidth: '400px',
        margin: '0 auto',
        textAlign: 'center',
      }}
    >
      {/* <h2>Change Password</h2> */}

      {/* New password input */}
      <TextField
        label="New Password"
        type="password"
        fullWidth
        sx={{ marginBottom: '20px' }}
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />

      {/* Confirm password input */}
      <TextField
        label="Confirm Password"
        type="password"
        fullWidth
        sx={{ marginBottom: '20px' }}
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />

      {/* Error message */}
      {errorMessage && (
        <Alert severity="error" sx={{ marginBottom: '20px' }}>
          {errorMessage}
        </Alert>
      )}

      {/* Success message */}
      {successMessage && (
        <Alert severity="success" sx={{ marginBottom: '20px' }}>
          {successMessage}
        </Alert>
      )}

      {/* Submit button */}
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleChangePassword}
      >
        Change Password
      </Button>
    </Box>
  );
}
