import React, { useState, useRef, useEffect } from 'react';
import { Autocomplete } from "@react-google-maps/api";
import { Button, TextField, Box, InputAdornment, Typography, CircularProgress, Snackbar, Modal, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel, InputLabel, Select, MenuItem } from '@mui/material'; // Add missing components
import DateRangeIcon from '@mui/icons-material/DateRange';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InfoIcon from '@mui/icons-material/Info';
import BookingConfirmModal from './confirm';
import leaf from '../assets/images/leaf.png';
import car from '../assets/images/car.png';
import person from '../assets/images/people.png';
import large from '../assets/images/large-bag.png';
import small from '../assets/images/small-bag.png';
import { slotdates, slotAvailability, estimatePrice, confirmBooking } from '../services/api'; 
import { convertToAmPm } from '../services/helper'; 

export default function Location({ selectedUser }) {
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState('');
  const [fare, setFare] = useState(null);
  const [com,setCom] = useState();
  const [initFare,setInitFare] = useState();
  const [co, setCo] = useState("");
  const [vehicle, setVehicle] = useState(null);
  const [request, setRequest] = useState();
  const [bookingData, setBookingData] = useState();
  const [pickNote, setpickNote] = useState("");
  const [loadingFare, setLoadingFare] = useState(false); 
  const [loadingBooking, setLoadingBooking] = useState(false); 
  const [priceTable, setPriceTable] = useState({});
  const [error, setError] = useState(null);
  const [showBookingOptions, setShowBookingOptions] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openPriceModal, setOpenPriceModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [paymentMode, setPaymentMode] = useState('offline'); // For radio button selection

  // Define autocomplete refs
  const autocompleteRefPickup = useRef(null);
  const autocompleteRefDest = useRef(null);

  const pickupref = useRef(null);
  const destref = useRef(null);
  const [pickupPlace, setPickupPlace] = useState(null);
  const [destinationPlace, setDestinationPlace] = useState(null);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleOpenPriceModal = () => setOpenPriceModal(true);
  const handleClosePriceModal = () => setOpenPriceModal(false);

  const autocompleteOptions = {
    componentRestrictions: { country: "au" },
  };

  const handlePlaceSelectPickup = () => {
    if (autocompleteRefPickup.current && autocompleteRefPickup.current.getPlace) {
      const place = autocompleteRefPickup.current.getPlace();
      setPickupPlace({
        name: pickupref.current.value,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    }
  };

  const handlePlaceSelectDest = () => {
    if (autocompleteRefDest.current && autocompleteRefDest.current.getPlace) {
      const place = autocompleteRefDest.current.getPlace();
      setDestinationPlace({
        name: destref.current.value,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    }
  };

  useEffect(() => {
    const fetchDates = async () => {
      try {
        const response = await slotdates("", localStorage.getItem('token'));
        if (response.status === 200) setDates(response.data.slots);
      } catch (error) {
        console.error("Error fetching dates:", error);
      }
    };
    fetchDates();
  }, []);

  const fetchSlots = async (date) => {
    try {
      const response = await slotAvailability("", date, "", localStorage.getItem('token'));
      if (response.status === 200) setSlots(response.data[date] || []);
    } catch (error) {
      console.error("Error fetching slots:", error);
    }
  };

  const estimateFare = async () => {
    if (pickupPlace && destinationPlace) {
      setLoadingFare(true); 
      setError(null);
      setShowBookingOptions(false);
      try {
        const response = await estimatePrice({
          startLocation: {
            name: pickupref.current.value,
            coordinates: [pickupPlace.lng, pickupPlace.lat],
          },
          endLocation: {
            name: destref.current.value,
            coordinates: [destinationPlace.lng, destinationPlace.lat],
          },
          user: selectedUser
        }, localStorage.getItem('token'));

        if (response.status === 200 && response.data.success) {
          setFare(response.data.packages[0].totalFare);
          setCo(response.data.co2Prediction);
          setRequest(response.data.id);
          setPriceTable(response.data.packages[0]);
          setShowBookingOptions(true); 
          setCom(response.data.commission);
          setInitFare(response.data.packages[0].initialTotalFare);

        } else {
          setError(response.data.message || 'Unable to estimate fare.');
          setShowToast(true);
        }
      } catch (error) {
        setError("Error occurred while estimating the fare.");
        setShowToast(true); 
      }
      setLoadingFare(false); 
    }
  };

  useEffect(() => {
    if (pickupPlace && destinationPlace) estimateFare();
  }, [pickupPlace, destinationPlace]);

  const changeTime = (e) => {
    setSelectedSlot(e.target.value);
    setVehicle(slots[e.target.value]["id"]);
  };

  const booking = async () => {
    if (!paymentMode) {
      console.log(error,paymentMode)
      setError('Please select a payment mode');
      return;
    }

    setLoadingBooking(true);
    const timeslot = slots[selectedSlot]["time"];
    const slot = `${selectedDate} ${timeslot}`;
    let data = {
      fullName: selectedUser.name,
      phoneNumber: selectedUser.phoneNumber,
      requestId: request,
      vehicleId: vehicle,
      slot: slot,
      pickNote,
      mode: paymentMode === 'online' ? 'online' : 'offline', // Pass payment mode
    };

    try {
      const response = await confirmBooking(data, localStorage.getItem("token"));
      if (!response.data.status) {
        setError(response.data.message);
        setShowToast(true);
        setLoadingBooking(false); 
        return;
      }
      setBookingData(response.data.booking);
      setOpenModal(true);
    } catch (err) {
      setError("Booking failed, please try again.");
      setShowToast(true);
    }
    setLoadingBooking(false); 
  };

  return (
    <>
      <Box
        sx={{
          padding: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          gap: 2,
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Box sx={{ width: { xs: '100%', md: '60%' } }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {/* Autocomplete for Pickup Location */}
            <Autocomplete
              options={autocompleteOptions}
              onLoad={(autocomplete) => (autocompleteRefPickup.current = autocomplete)}
              onPlaceChanged={handlePlaceSelectPickup}
            >
              <TextField inputRef={pickupref} label="Current Location" variant="outlined" fullWidth />
            </Autocomplete>

            {/* Autocomplete for Destination Location */}
            <Autocomplete
              options={autocompleteOptions}
              onLoad={(autocomplete) => (autocompleteRefDest.current = autocomplete)}
              onPlaceChanged={handlePlaceSelectDest}
            >
              <TextField inputRef={destref} label="Enter Destination" variant="outlined" fullWidth />
            </Autocomplete>
          </Box>

          {/* Show Loader while fetching */}
          {loadingFare && <CircularProgress size={40} sx={{ display: 'block', margin: '20px auto' }} />}

          {/* Show Booking Options only when the fare is estimated */}
          {showBookingOptions && (
            <>
              <Box sx={{ marginTop: 3 }}>
                <FormControl fullWidth>
                  <InputLabel>Date</InputLabel>
                  <Select
                    value={selectedDate}
                    onChange={(e) => {
                      setSelectedDate(e.target.value);
                      fetchSlots(e.target.value);
                    }}
                    endAdornment={<InputAdornment position="end"><DateRangeIcon /></InputAdornment>}
                  >
                    {dates.map((date, key) => (
                      <MenuItem key={key} value={date}>{date}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ marginTop: 3 }}>
                <FormControl fullWidth>
                  <InputLabel>Time</InputLabel>
                  <Select
                    value={selectedSlot}
                    onChange={changeTime}
                    endAdornment={<InputAdornment position="end"><AccessTimeIcon /></InputAdornment>}
                  >
                    {slots.map((slot, key) => (
                      <MenuItem key={key} value={key}>{convertToAmPm(slot.time)}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <TextField
                label="Pickup Note"
                variant="outlined"
                fullWidth
                value={pickNote}
                onChange={(e) => setpickNote(e.target.value)}
                sx={{ marginTop: 3 }}
                placeholder="Any special instructions for the driver?"
              />

              {/* Payment Mode Radio Buttons */}
              {/* <FormControl component="fieldset" sx={{ marginTop: 3 }}>
                <FormLabel component="legend">Payment Mode</FormLabel>
                <RadioGroup
                  value={paymentMode}
                  onChange={(e) => setPaymentMode(e.target.value)}
                >
                  <FormControlLabel value="online" control={<Radio />} label="Pay with card" />
                  <FormControlLabel value="offline" control={<Radio />} label="Pay after ride" />
                </RadioGroup>
              </FormControl> */}

              {/* On small screen, CO2, vehicle, and commission details before the button */}
              <Box sx={{ display: { xs: 'block', md: 'none' }, marginTop: 3 }}>
                {co && (
                  <Box sx={{ padding: 2, backgroundColor: '#f0f0f0', borderRadius: 1, marginBottom: 3 }}>
                    <img src={leaf} alt="CO2" style={{ width: 30, verticalAlign: 'middle' }} />
                    <Typography variant="body1" sx={{ display: 'inline-block', marginLeft: 2 }}>
                      You will save {co}kg CO2 with this ride
                    </Typography>
                  </Box>
                )}
                <Box sx={{ padding: 2, borderRadius: 1, boxShadow: 1, backgroundColor: '#fff' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <img src={car} alt="car" />
                    <Box>
                      <Typography variant="body2">BYD Atto 3</Typography>
                      <Typography variant="body2">4 Persons, 2 Large Bags, 2 Small Bags</Typography>
                    </Box>
                  </Box>
                </Box>
                {fare && (
                  <>
                    {/* <Typography sx={{ marginTop: 2 }}>Commission Earning: A${(com).toFixed(2)}</Typography> */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2 }}>
                    <Typography variant="h6" fontWeight="bold">
  Total Fare: 
  <span style={{ textDecoration: 'line-through', marginRight: '8px', color: '#757575' }}>
    A${initFare.toFixed(2)}
  </span>
  A${fare.toFixed(2)}
</Typography>
                      <InfoIcon onClick={handleOpenPriceModal} sx={{ cursor: 'pointer', color: 'blue' }} />
                    </Box>
                  </>
                )}
              </Box>

              {/* Show Loader when booking is in progress */}
              {loadingBooking ? (
                <CircularProgress size={30} sx={{ display: 'block', margin: '20px auto' }} />
              ) : (
                <Button onClick={booking} variant="contained" sx={{ marginTop: 3, width: '100%' }}>
                  Continue
                </Button>
              )}
            </>
          )}
        </Box>

        {/* Responsive second column */}
        <Box sx={{ width: { xs: '100%', md: '35%' }, display: { xs: 'none', md: 'block' } }}>
          {co && (
            <Box sx={{ padding: 2, backgroundColor: '#f0f0f0', borderRadius: 1, marginBottom: 3 }}>
              <img src={leaf} alt="CO2" style={{ width: 30, verticalAlign: 'middle' }} />
              <Typography variant="body1" sx={{ display: 'inline-block', marginLeft: 2 }}>
                You will save {co}kg CO2 with this ride
              </Typography>
            </Box>
          )}
          <Box sx={{ padding: 2, borderRadius: 1, boxShadow: 1, backgroundColor: '#fff' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <img src={car} alt="car" />
              <Box>
                <Typography variant="body2">BYD Atto 3</Typography>
                <Typography variant="body2">4 Persons, 2 Large Bags, 2 Small Bags</Typography>
              </Box>
            </Box>
          </Box>
          {fare && (
            <>
                               {/* <Typography sx={{ marginTop: 2 }}>Commission Earning: A${(com).toFixed(2)}</Typography> */}

              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2 }}>
              <Typography variant="h6" fontWeight="bold">
  Total Fare: 
  <span style={{ textDecoration: 'line-through', marginRight: '8px', color: '#757575' }}>
    A${initFare.toFixed(2)}
  </span>
  A${fare.toFixed(2)}
</Typography>
                <InfoIcon onClick={handleOpenPriceModal} sx={{ cursor: 'pointer', color: 'blue' }} />
              </Box>
            </>
          )}
        </Box>
      </Box>

      <BookingConfirmModal open={openModal} bookingData={bookingData} handleClose={handleCloseModal} />

      <Modal open={openPriceModal} onClose={handleClosePriceModal}>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400, // Increased width for better table display
      backgroundColor: 'white',
      padding: 4,
      borderRadius: 2,
      boxShadow: 24,
    }}
  >
    <Typography variant="h6" sx={{ marginBottom: 2, textAlign: 'center' }}>
      Price Breakdown
    </Typography>
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <tbody>
        <tr>
          <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>Base Fare:</td>
          <td style={{ textAlign: 'right', padding: '8px', borderBottom: '1px solid #ddd' }}>
            A${priceTable.baseFare?.toFixed(2)}
          </td>
        </tr>
        <tr>
          <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>Toll Fare:</td>
          <td style={{ textAlign: 'right', padding: '8px', borderBottom: '1px solid #ddd' }}>
            A${priceTable.tollFare?.toFixed(2)}
          </td>
        </tr>
        <tr>
          <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>GST:</td>
          <td style={{ textAlign: 'right', padding: '8px', borderBottom: '1px solid #ddd' }}>
            A${priceTable.gst?.toFixed(2)}
            
          </td>
        </tr>
        <tr>
          <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>Discount:</td>
          <td style={{ textAlign: 'right', padding: '8px', borderBottom: '1px solid #ddd' }}>
            - A${priceTable.discount?.toFixed(2)}
          </td>
        </tr>
        <tr>
          <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>Govt Levy:</td>
          <td style={{ textAlign: 'right', padding: '8px', borderBottom: '1px solid #ddd' }}>
            A${priceTable.govtLevy?.toFixed(2)}
          </td>
        </tr>
        <tr>
          <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>Surcharge:</td>
          <td style={{ textAlign: 'right', padding: '8px', borderBottom: '1px solid #ddd' }}>
            A${priceTable.surcharge?.toFixed(2)}
          </td>
        </tr>
        <tr>
          <td style={{ padding: '8px', fontWeight: 'bold' }}>Total:</td>
          <td style={{ textAlign: 'right', padding: '8px', fontWeight: 'bold' }}>
            A${priceTable.totalFare?.toFixed(2)}
          </td>
        </tr>
      </tbody>
    </table>
  </Box>
</Modal>



      {/* Snackbar for Error Toast */}
      <Snackbar
        open={showToast}
        autoHideDuration={6000}
        onClose={() => setShowToast(false)}
        message={error}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }} 
        ContentProps={{
          sx: { backgroundColor: 'red' }, 
        }}
      />
    </>
  );
}
