import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Location from './location';
import { getUserList } from '../services/api'; // Assuming you have an API call setup to fetch user data
import CircularProgress from '@mui/material/CircularProgress'; // Loading indicator

export default function Booking() {
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState({ name: '', employeeID: '' });

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await getUserList("", localStorage.getItem('token')); // Replace with the appropriate API call
        const emailOptions = response.data.map((user) => ({
          label: `${user.fullName} (${user.phoneNumber})`, // Display both name and phone number
          id: user.id, // Assuming _id is the unique identifier
          name: user.fullName, // Assuming the user object contains a name field
          employeeID: user.employeeID || user.id, // Assuming employeeID or fallback to _id,
          email: user.email,
          phoneNumber: user.phoneNumber,
          _id: user.id
        }));
        setEmails(emailOptions);
      } catch (error) {
        console.error('Failed to fetch user list:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmails();
  }, []);

  const handleEmailChange = (event, value) => {
    if (value) {
      setSelectedUser({
        name: value.name,
        employeeID: value.employeeID,
        email: value.email,
        phoneNumber: value.phoneNumber,
        _id: value.employeeID
      });
    } else {
      setSelectedUser({ name: '', employeeID: '' });
    }
  };

  return (
    <>
      <Box
        sx={{
          padding: '20px',
          maxWidth: '600px',
          margin: '0 auto', // Centers the container
          textAlign: 'center', // Centers content inside the container
        }}
      >
        {/* heading */}
        <h1 style={{ marginBottom: '20px' }}>Book a Ride</h1>

        {/* field for phone number or name search */}
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={emails}
          loading={loading}
          onChange={handleEmailChange}
          getOptionLabel={(option) => option.label} // Allows searching by both name and phone number
          sx={{ marginBottom: '20px' }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search by Name or Phone Number"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.id}> {/* Using id as the unique key */}
              {option.label}
            </li>
          )}
        />

        {/* field for name and employee ID */}
        <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px', justifyContent: 'center' }}>
          <TextField
            id="outlined-name"
            label="Name"
            value={selectedUser.name}
            disabled
            sx={{ minWidth: '250px' }}
          />
          {/* <TextField
            id="outlined-employeeID"
            label="Employee ID"
            value={selectedUser.employeeID}
            disabled
            sx={{ minWidth: '150px' }}
          /> */}
        </Box>
      </Box>

      {selectedUser.name && (
        <Box>
          <Location selectedUser={selectedUser} />
        </Box>
      )}
    </>
  );
}
