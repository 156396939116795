import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Paper, Grid, Divider, IconButton, Avatar, CircularProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { profile, updateProfile } from './services/api';  // Assuming you have an updateProfile API function
import ChangePassword from './components/changePassword';

export default function PartnerDetails() {
  const [partnerData, setPartnerData] = useState(null); // Initially null to show loader
  const [editMode, setEditMode] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState(null); // For handling the logo upload
  const [loading, setLoading] = useState(true); // To show loading spinner during data fetching
  const [uploadingLogo, setUploadingLogo] = useState(false); // To show loading spinner during logo upload
  const [savingProfile, setSavingProfile] = useState(false); // Loader for saving profile

  const fetchPartnerDetails = async () => {
    try {
      const partnerDetails = await profile(localStorage.getItem('token'));
      setPartnerData(partnerDetails.data);
    } catch (error) {
      console.error('Error fetching partner details:', error);
    } finally {
      setLoading(false); // Stop loading spinner once data is fetched
    }
  };

  useEffect(() => {
    fetchPartnerDetails();
  }, []);

  // Toggle between view and edit mode
  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
  };

  // Handle field changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPartnerData({ ...partnerData, [name]: value });
  };

  // Handle logo change
  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    setSelectedLogo(file); // Store the file for uploading
    setUploadingLogo(true); // Start logo upload loader
    setTimeout(() => {
      setPartnerData({ ...partnerData, logo: URL.createObjectURL(file) }); // Preview the logo
      setUploadingLogo(false); // Stop logo upload loader
    }, 1000); // Simulate logo upload time, replace with actual API call
  };

  // Handle form submission and update profile
  const handleSave = async () => {
    const formData = new FormData();

    // Append form fields to the formData object
    formData.append('name', partnerData.name);
    formData.append('registeredName', partnerData.registeredName);
    formData.append('pocName', partnerData.pocName);
    formData.append('email', partnerData.email);
    formData.append('phoneNumber', partnerData.phoneNumber);
    formData.append('telephoneNumber', partnerData.telephoneNumber);
    formData.append('registrationNumber', partnerData.registrationNumber);
    formData.append('username', partnerData.username);

    // Append the logo file if it was selected
    if (selectedLogo) {
      formData.append('image', selectedLogo);
    }

    try {
      setSavingProfile(true); // Start save loader
      // Call the update profile API (you may replace this with your API service)
      await updateProfile(formData, localStorage.getItem('token'));

      // If successful, toggle edit mode off
      toggleEditMode();
    } catch (error) {
      console.error('Error updating profile:', error);
    } finally {
      setSavingProfile(false); // Stop save loader
    }
  };

  return (
    <>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
<>
<Box sx={{ padding: 4 }}>
          <Paper elevation={3} sx={{ padding: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h4" fontWeight="bold">
                Partner Details
              </Typography>
              <IconButton onClick={toggleEditMode}>
                {editMode ? <CancelIcon /> : <EditIcon />}
              </IconButton>
            </Box>

            <Divider sx={{ marginY: 2 }} />

            {/* Logo Section */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}> {/* Center align the logo */}
                <Typography variant="h6">Logo</Typography>
                {editMode ? (
                  <>
                    <Button
                      variant="outlined"
                      component="label"
                      startIcon={<FileUploadIcon />}
                      sx={{ marginY: 1 }}
                    >
                      Upload Logo
                      <input hidden type="file" onChange={handleLogoChange} />
                    </Button>
                    {partnerData.logo && (
                      <Avatar
                        alt="Logo"
                        src={partnerData.logo}
                        sx={{ width: 100, height: 100, marginTop: 2, marginX: 'auto' }} // Centered avatar
                      />
                    )}
                    {uploadingLogo && <CircularProgress size={24} sx={{ marginTop: 2 }} />} {/* Loader for logo upload */}
                  </>
                ) : (
                  <Avatar
                    alt="Logo"
                    src={partnerData.logo}
                    sx={{ width: 100, height: 100, marginX: 'auto' }} // Centered avatar
                  />
                )}
              </Grid>

              {/* Details Section */}
              <Grid item xs={12} md={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Name"
                      value={partnerData.name}
                      name="name"
                      onChange={handleInputChange}
                      disabled={!editMode}
                    />
                  </Grid>
            
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="POC Name"
                      value={partnerData.pocName}
                      name="pocName"
                      onChange={handleInputChange}
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      value={partnerData.email}
                      name="email"
                      onChange={handleInputChange}
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      value={partnerData.phoneNumber}
                      name="phoneNumber"
                      onChange={handleInputChange}
                      disabled={!editMode}
                    />
                  </Grid>
       
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Username"
                      value={partnerData.username}
                      name="username"
                      onChange={handleInputChange}
                      disabled={!editMode}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

     

            {/* Action Buttons */}
            {editMode && (
              <Box sx={{ marginTop: 4, textAlign: 'center' }}>
                <Button
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={handleSave}
                  sx={{ marginRight: 2 }}
                  disabled={savingProfile} // Disable button while saving
                >
                  {savingProfile ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : 'Save'}
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<CancelIcon />}
                  onClick={toggleEditMode}
                  disabled={savingProfile} // Disable button while saving
                >
                  Cancel
                </Button>
              </Box>
            )}
          </Paper>
        </Box>


        <Box sx={{ padding: 4 }}>
<Paper elevation={3} sx={{ padding: 4 }}>

<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h4" fontWeight="bold">
                Change Password
              </Typography>
      
            </Box>
<ChangePassword/>
</Paper>
</Box>
</>

      )}
    </>
  );
}
