import React from 'react';
import { BsWhatsapp } from 'react-icons/bs';

export default function Whatsappbtn() {
  return (
    <div style={containerStyle}>
      <a
        href="https://api.whatsapp.com/send?phone=61436108248&text="
        target="_blank"
        rel="noopener noreferrer"
        style={linkStyle}
        className="pulse" // Added class for pulse animation
      >
        <BsWhatsapp style={iconStyle} />
      </a>
    </div>
  );
}

const containerStyle = {
  position: 'fixed',
  bottom: '1.5rem',
  right: '1.5rem',
  zIndex: 50,
};

const linkStyle = {
  display: 'inline-block',
  backgroundColor: '#25D366', // Updated WhatsApp green color
  borderRadius: '50%', // Makes it a perfect circle
  padding: '16px', // Padding to make it circular
  boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)', // Softer shadow
  textDecoration: 'none',
  cursor: 'pointer',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out', // Smooth hover effect
  width: '64px', // Ensures the container stays a circle
  height: '64px',
};

const iconStyle = {
  fontSize: '32px', // Increased font size for better visibility
  color: 'white',
};

// Hover and animation effect
linkStyle[':hover'] = {
  transform: 'scale(1.1)', // Slightly increases size on hover
  boxShadow: '0px 12px 20px rgba(0, 0, 0, 0.2)', // Enhances shadow on hover
};
