import React from 'react'
import UploadCSV from './addUser'
import StickyHeadTable from './userList'
import EmployeesHeader from './addUserNew'

export default function Employees() {
  return (
    <div>
        {/* <h1>Add Employees</h1> */}
        {/* <UploadCSV/> */}
        <EmployeesHeader/>

        <h1>User List</h1>
        <StickyHeadTable/>
    </div>
  )
}
