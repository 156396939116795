import * as React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Avatar,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  Menu as MenuIcon,
  People as PeopleIcon,
  LocalTaxi as LocalTaxiIcon,
  CalendarToday as CalendarTodayIcon,
  AccessAlarm as AccessAlarmIcon,
  ShowChart as ShowChartIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';
import { deepOrange, green } from '@mui/material/colors';
import { Routes, Route, useNavigate, Link, useLocation } from 'react-router-dom';
import Employees from './components/user';
import Booking from './components/booking';
import PastRides from './components/pastRides';
import UpcomingRides from './components/upcomingRides';
import Tracker from './components/data';
import NotFoundPage from './loader/error';
import PartnerDetails from './profile';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReportsDownload from './components/report';
import Whatsappbtn from './components/whatsapp';
import Fleet from './components/fleet';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import PaymentIcon from '@mui/icons-material/Payment';
import PaymentMethods from './components/paymentMethods';

const drawerWidth = 240;

function Dashboard(props) {
  const { wid } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleNavigation = (url) => {
    navigate(url);
    setMobileOpen(false); // Close drawer on mobile after navigation
  };

  const handleLogout = () => {
    localStorage.clear(); // Clear local storage
    window.location.href = "";
  };

  const menuItems = [
    { text: 'Tracker', icon: <ShowChartIcon />, url: '/' },
    { text: 'Employees', icon: <PeopleIcon />, url: '/employees' },
    { text: 'Book', icon: <LocalTaxiIcon />, url: '/bookings' },
    { text: 'Past', icon: <CalendarTodayIcon />, url: '/pastbookings' },
    { text: 'Upcoming', icon: <AccessAlarmIcon />, url: '/upcomingbookings' },
    { text: 'Invoice', icon: <ReceiptIcon />, url: '/reports' },
    // { text: 'Payment Methods', icon: <PaymentIcon />, url: '/methods' },
    { text: 'Fleets', icon: <TimeToLeaveIcon />, url: '/fleets' },

  ];

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {menuItems.map(({ text, icon, url }) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              onClick={() => handleNavigation(url)}
              selected={location.pathname === url} // Highlight the active tab
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Sign Out" />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const container = typeof window !== "undefined" ? () => window.document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Dashboard
          </Typography>
          <div>
            <IconButton color="inherit" component={Link} to="/profile">
              <Avatar sx={{ bgcolor: 'white' }} src={localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).logo} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MenuItem onClick={handleLogout}>Log out</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <Routes>
          <Route path="/" element={<Tracker />} />
          <Route path="/employees" element={<Employees />} />
          <Route path="/bookings" element={<Booking />} />
          <Route path="/pastbookings" element={<PastRides />} />
          <Route path="/upcomingbookings" element={<UpcomingRides />} />
          <Route path="/profile" element={<PartnerDetails />} />
          <Route path="/reports" element={<ReportsDownload />} />
          <Route path="/fleets" element={<Fleet />} />
          {/* <Route path="/methods" element={<PaymentMethods />} /> */}
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
      </Box>
      <Whatsappbtn/>
    </Box>
  );
}

Dashboard.propTypes = {
  wid: PropTypes.func,
};

export default Dashboard;
