import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "./dashboard";
import LoginPage from "./pages/auth";
import { useStateValue } from "./context/StateProvider"; // Import the useStateValue hook from your state context
import { getUser } from "./services/api";
import Signup from "./pages/signup";
import ReviewRequest from "./pages/verify";
import MultiStepForm from "./pages/multi";



export default function App() {
  const [{ user, token }] = useStateValue(); // Destructure user and token from the state
  const navigate = useNavigate(); // Access the navigate function from React Router
  const location = useLocation(); // Access the current lo
const isAuthenticated = false;

const isTokenValid = async (token) => {
  try {

    const response = await getUser("", localStorage.getItem("token"));
   

    if (response.status === 200 ) {
      localStorage.setItem("user", JSON.stringify(response.data));
    } else {
       
      localStorage.removeItem("user");

      localStorage.removeItem("token");
      navigate("/")
    }
  } catch (error) {
     
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/")
  }
};

// Perform the redirect based on the state
useEffect(() => {
  if (location.pathname !== '/sign-up') {
    isTokenValid();
  }
}, [navigate, location.pathname]);

return (<>
{/* <Employees/> */}
{/* <LoginPage/> */}
{/* <Booking/> */}
{/* <PastRides/> */}
{/* <UpcomingRides/> */}
{/* <Tracker/> */}

  
        {
          token && user ? <>
       <Dashboard/>
          </> : <>
       
       <Routes>
        <Route path="/" element={   <LoginPage/>} />
        <Route path="/sign-up" element={   <MultiStepForm/>} />
        {/* <Route path="/" element={   <ReviewRequest/>} /> */}


       </Routes>
          </>
        }
        
</>)
}
