import React, { useState } from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/system';
import Papa from 'papaparse';

const Input = styled('input')({
  display: 'none',
});

const UploadCSV = () => {
  const [fileName, setFileName] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      parseCSV(file);
    }
  };

  const parseCSV = (file) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        console.log('Parsed Results:', results.data);
      },
      error: (error) => {
        console.error('Error parsing CSV:', error);
      },
    });
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4, textAlign: 'center' }}>
      <Typography variant="h5" component="h1" gutterBottom>
        Upload User List (CSV)
      </Typography>
      <Box sx={{ mt: 2 }}>
        <label htmlFor="csv-upload">
          <Input
            accept=".csv"
            id="csv-upload"
            type="file"
            onChange={handleFileChange}
          />
          <Button variant="contained" component="span">
            {fileName || 'Choose CSV File'}
          </Button>
        </label>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="body1">
          {fileName ? `Selected file: ${fileName}` : 'No file selected'}
        </Typography>
      </Box>
    </Container>
  );
};

export default UploadCSV;
